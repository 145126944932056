@tailwind base;
@tailwind components;
@tailwind utilities;
 
@layer base {
  :root {
    --background: 0 0% 100%;
    --foreground: 222.2 84% 4.9%;
 
    --muted: 210 40% 96.1%;
    --muted-foreground: 215.4 16.3% 46.9%;
 
    --popover: 0 0% 100%;
    --popover-foreground: 222.2 84% 4.9%;
 
    --card: 0 0% 100%;
    --card-foreground: 222.2 84% 4.9%;
 
    --border: 214.3 31.8% 91.4%;
    --input: 214.3 31.8% 91.4%;
 
    --primary: 222.2 47.4% 11.2%;
    --primary-foreground: 210 40% 98%;
 
    --secondary: 210 40% 96.1%;
    --secondary-foreground: 222.2 47.4% 11.2%;
 
    --accent: 210 40% 96.1%;
    --accent-foreground: 222.2 47.4% 11.2%;
 
    --destructive: 0 84.2% 60.2%;
    --destructive-foreground: 210 40% 98%;
 
    --ring: 215 20.2% 65.1%;
 
    --radius: 0.5rem;
  }
 
  .dark {
    --background: 222.2 84% 4.9%;
    --foreground: 210 40% 98%;
 
    --muted: 217.2 32.6% 17.5%;
    --muted-foreground: 215 20.2% 65.1%;
 
    --popover: 222.2 84% 4.9%;
    --popover-foreground: 210 40% 98%;
 
    --card: 222.2 84% 4.9%;
    --card-foreground: 210 40% 98%;
 
    --border: 217.2 32.6% 17.5%;
    --input: 217.2 32.6% 17.5%;
 
    --primary: 210 40% 98%;
    --primary-foreground: 222.2 47.4% 11.2%;
 
    --secondary: 217.2 32.6% 17.5%;
    --secondary-foreground: 210 40% 98%;
 
    --accent: 217.2 32.6% 17.5%;
    --accent-foreground: 210 40% 98%;
 
    --destructive: 0 62.8% 30.6%;
    --destructive-foreground: 0 85.7% 97.3%;
 
    --ring: 217.2 32.6% 17.5%;
  }
}
 
@layer base {
  * {
    @apply border-border;
  }
  body {
    @apply bg-background text-foreground;
  }
}

.custom-scrollbar::-webkit-scrollbar {
  width: 8px;
}

.custom-scrollbar::-webkit-scrollbar-track {
  background: hsl(var(--background));
}

.custom-scrollbar::-webkit-scrollbar-thumb {
  background-color: hsl(var(--muted-foreground));
  border-radius: 20px;
  border: 2px solid hsl(var(--background));
}

.custom-scrollbar {
  scrollbar-width: thin;
  scrollbar-color: hsl(var(--muted-foreground)) hsl(var(--background));
}

@layer base {
  html {
    font-size: 14px;
  }
  
  @screen sm {
    html {
      font-size: 16px;
    }
  }
}

midi-player::part(seek-bar) {
  accent-color: #f05477;
}


midi-player::part(play-button) {
  background-color: #f05477; /* Pink background */
  color: white; /* White text or icon */
}

midi-player::part(play-button):hover {
  background-color: #d94466; /* Darker pink on hover */
}

midi-player::part(play-button):active {
  background-color: #c03355; /* Even darker pink when active */
}

midi-player::part(time) {
  font-family: monospace;
}

@keyframes flashInput {
  0%, 100% { 
    box-shadow: 0 0 0 0 rgba(240, 84, 119, 0);
    transform: scale(1);
  }
  50% { 
    box-shadow: 0 0 0 4px rgba(240, 84, 119, 0.2);
    transform: scale(1.02);
  }
}

.input-flash {
  animation: flashInput 0.4s ease-out;
}

@layer base {
  input, textarea, select {
    @apply text-base;
  }
}

/* Add this to your global CSS file or component-specific CSS */
input[type="radio"] {
  appearance: none;
  -webkit-appearance: none;
  width: 16px;
  height: 16px;
  border: 2px solid #ccc;
  border-radius: 50%;
  outline: none;
  margin-right: 8px;
  transition: all 0.2s ease-in-out;
}

input[type="radio"]:checked {
  border-color: #646cff;
  background-color: #646cff;
  box-shadow: inset 0 0 0 3px white;
}

input[type="radio"]:focus {
  box-shadow: 0 0 0 2px rgba(100, 108, 255, 0.3);
}

.full-viewport-height {
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
}

@supports (height: 100dvh) {
  .full-viewport-height {
    height: 100dvh;
  }
}
